import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { KsolvesDataFlowIcon } from '../assets';
import { ALREADY_HAVE_AN_ACCOUNT, SIGN_IN } from '../constants';
import { history } from '../helpers/history';
import { TextButton } from '../shared';
import { SettingsActions, SettingsSelectors } from '../store/settings';

const Container = styled.div`
  min-height: 100vh;
  padding: 0px 15px;
`;

const LeftSection = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
`;

const RightSection = styled.div`
  position: relative;
  height: 100vh;
  background-image: url('/img/aside-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-color: #fff7ed;
  position: fixed;
  top: 0;
  right: 0;
  p {
    font-weight: 700;
    max-width: 540px;
    margin: 0 auto;
  }
`;
const Image = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-image: url('/img/right-logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* @media (max-width: 1440px) and (min-width: 992px) {
    background-size: 90%;
  } */
  /* @media (max-width: 1660px) and (min-width: 1441px) {
    background-size: 80%;
  }
  @media (max-width: 1800px) and (min-width: 1661px) {
    background-size: 75%;
  }
  @media (max-width: 1300px) and (max-height: 990px) {
    background-size: 75%;
  } */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 470px;
  width: 100%;
  background-color: ${props => props.theme.colors.lightGrey};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 16px;
  padding: 25px 32px 32px 32px;
  /* margin-top: 20px; */
`;

const RedirectionSection = styled.div`
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
  color: #757575;
  margin-top: 10px;
`;

const RedirectionText = styled.button`
  border: none;
  background-color: transparent;
  font-family: Red Hat Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.17px;
  text-align: left;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  margin-left: 5px;
`;

const RightSectionTextContainer = styled.div`
  /* position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  text-align: center; */
`;

const HeadingRightText = styled.p`
  font-family: Red Hat Display;
  font-size: 25px;
  font-weight: 700;
  line-height: 47.63px;
  letter-spacing: 0.08em;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 2rem;
    line-height: 24.63px;
  }
  @media (max-width: 1300px) and (max-height: 990px) {
    font-size: 1.5rem;
    line-height: 32px;
  }
`;

const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;

  button {
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    font-weight: 700;
    margin-left: 5px;
    text-decoration: none;
  }
`;

const PolicyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  margin-top: 20px;
`;

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const pathname = history.location.pathname;
  const isUserLogin = pathname === '/login';
  const isAdminLogin = pathname === '/admin/login';
  const isForgotPassword = pathname === '/forgot';
  const isReset = pathname === '/reset';
  const settingsData = useSelector(SettingsSelectors.getSettings);
  let image = settingsData?.logo;

  useEffect(() => {
    dispatch(SettingsActions.fetchSettings());
  }, [dispatch]);

  return (
    <Container>
      <div className="row">
        <LeftSection className="col-xl-5 col-lg-5">
          {!image ? (
            <KsolvesDataFlowIcon width={160} height={110} />
          ) : (
            <img src={image} alt="Logo" width={200} height={80} />
          )}
          <Content>{children}</Content>
          {(isUserLogin || isAdminLogin) && (
            <RedirectionSection>
              Login via
              <RedirectionText
                onClick={() =>
                  history.push(isUserLogin ? '/admin/login' : '/login')
                }
              >
                {isUserLogin ? 'Admin' : 'User'}
              </RedirectionText>
            </RedirectionSection>
          )}
          {(isForgotPassword || isReset) && (
            <SignInContainer>
              {ALREADY_HAVE_AN_ACCOUNT}
              <TextButton onClick={() => history.replace('/login')}>
                {SIGN_IN}
              </TextButton>
            </SignInContainer>
          )}
          {(isUserLogin || isAdminLogin) && (
            <PolicyContainer>
              <RedirectionText
                onClick={() => history.push('/policy/privacy-policy')}
              >
                Privacy Policy
              </RedirectionText>
              <RedirectionText
                onClick={() => history.push('/policy/terms-of-use')}
              >
                Terms Of Use
              </RedirectionText>
            </PolicyContainer>
          )}
        </LeftSection>

        <RightSection className="col-xl-7 col-lg-7 d-none d-lg-flex flex-column">
          <Image />
          <RightSectionTextContainer>
            <HeadingRightText>
              Check out the Best Data <br /> Flow Management Tool!
            </HeadingRightText>
            <br />
          </RightSectionTextContainer>
        </RightSection>
      </div>
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
