export const NAMESPACE_CONSTANTS = {
  NAMESPACE: 'Process Group', // TODO: change it to Process Group
  NAMESPACE_LIST: 'Process Group List',
  DEPLOY_NAMESPACE: 'Deploy Process Group',
  NAMESPACE_ID: 'Process Group ID',
  FLOW_NAME: 'Flow Name',
  BUCKET_NAME: 'Bucket Name',
  SELECT_NAMESPACE: 'Select Process Group',
  SELECTED_NAMESPACE: 'Selected Process Group',
  SEARCH_NAMESPACE_FLOW_BUCKET_NAME: 'Search Process Group, Id, Flow Name',
  CONFIGURATION_DETAILS: 'Configuration Details',
  VERSION_CONTROL: 'Version Control',
  CANVAS_POSITION: 'Canvas Position',
  CURRENT_POSITION: 'Current Position',
  CURRENT_VERSION: 'Current Version',
  CURRENT_STATE: 'Current State',
  UPDATED_VERSION: 'Updated Version',
  FLOW_CONTROL: 'Flow Control',
  RUNNING_PROCESSORS: 'Running Processors',
  STOPPED_PROCESSORS: 'Stopped Processors',
  INVALID_PROCESSORS: 'Invalid Processors',
  DISABLED_PROCESSORS: 'Disabled Processors',
  ENABLED_FLOW: 'Enable Flow',
  UPDATING_FLOW: 'Updating Flow',
  DISABLED_FLOW: 'Disable Flow',
  STOPPED_FLOW: 'Stop Flow',
  RUNNING_FLOW: 'Start Flow',
  SENSITIVE_VALUE_SET: 'Sensitive value set',
  EMPTY_STRING_SET: 'Empty string set',
  NO_VALUE_SET: 'No value set',
  PARAMETER_CONTEXT: 'Parameter Context',
  ADD_PARAMETER_CONTEXT: 'Add Parameter Context',
  EDIT_PARAMETER_CONTEXT: 'Edit Parameter Context',
  SET_EMPTY_STRING: 'Set empty string',
  SENSITIVE_VALUE: 'Sensitive value',
  VARIABLES: 'Variables',
  ADD_VARIABLES: 'Add Variables',
  EDIT_VARIABLES: 'Edit Variables',
  PARAMETER_ALREADY_EXISTS: 'Parameter with same name already exists',
  VARIABLE_ALREADY_EXISTS: 'Variable with same name already exists',
  ENTER_PARAMETER: 'Enter Parameter',
  ENTER_VARIABLE: 'Enter Variable',
  ENTER_DESCRIPTION: 'Enter Description',
};
